import {useCallback, useContext, useEffect, useMemo, useState} from "react";

import styles from "./styles/SidebarFeaturesPage.module.scss";
import classNames from "classnames";

import {observer} from 'mobx-react';
import {runInAction, values} from 'mobx';
import SidebarPage from "./SidebarPage";
import {StoreContext} from "../../../../stores/StoreLoader";
import WatsonApi from "../../../../backends/WatsonApi";
import NotificationManager from "../../../notifications/NotificationManager";
import {ITerraceTypes} from "../../../blocks/TerracedGridTheme/Terrace";
import {useGridType} from "../../../../hooks/useGridType";
import {GridTypes} from "../../../../pages/MainGridPage";
import * as React from "react";
import {OrganizationTypesWithStaffBlock} from "../../../../types/OrganizationTypeValues";
import NewTag from "../items/NewTag";

export const terraceTypeToTitleMap: {[value: string]: string} = {
    [ITerraceTypes.HERO]: "Hero Image",
    [ITerraceTypes.ABOUT]: "About",
    [ITerraceTypes.NEWS]: "News",
    [ITerraceTypes.EVENTS]: "Events",
    [ITerraceTypes.LINKS]: "Links",
    [ITerraceTypes.MEDIA]: "Gallery",
    [ITerraceTypes.FILES]: "Files & Folders",
    [ITerraceTypes.STAFF]: "Staff",
    [ITerraceTypes.HTML]: "HTML",
    [ITerraceTypes.MAP]: "Map",
    [ITerraceTypes.STATEMENT]: "Statement",
    [ITerraceTypes.SHORTCUTS]: "Shortcuts",
    [ITerraceTypes.SHOP]: "Shop",
    [ITerraceTypes.ACCORDIAN]: "Accordion",
}

const SidebarGridSettingsPage = observer((props) => {
    const {organizationStore, gridStore, interfaceStore} = useContext(StoreContext);
    const [newTerraceId, setNewTerraceId] = useState(null);

    const addFeature = useCallback(async (terraceType: ITerraceTypes) => {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_create({
                organization_pk: organizationStore.currentOrganization.id,
                data: {
                    organization_id: organizationStore.currentOrganization.id,
                    type: terraceType,
                    json_data: {},
                },
            })
            const newBlock = JSON.parse(response.data);
            gridStore.setBlocks([...values(gridStore.blocks), newBlock].sort((a, b) => {
                if (a.sort_order === null && b.sort_order !== null) {
                    return 1; // Place null values last
                } else if (a.sort_order !== null && b.sort_order === null) {
                    return -1; // Place null values last
                } else {
                    return a.sort_order - b.sort_order; // Default numerical sorting
                }
            }), true);
            NotificationManager.success(`${terraceTypeToTitleMap[terraceType]} terrace successfully added!`);
            setNewTerraceId(newBlock.id);
        } catch (e) {
            NotificationManager.error(e.message);
        }
    }, [gridStore.blocks]);

    useEffect(() => {
        if (newTerraceId) {
            interfaceStore.scrollToElement(`[data-id="${newTerraceId}"]`)
        }
    },[newTerraceId])

    const deleteFeature = useCallback(async (featureId) => {
        try {
            const client = await WatsonApi();
            await client.apis.organizations.organizations_terraces_partial_update({
                organization_pk: organizationStore.currentOrganization.id,
                id: featureId,
                data: {
                    deleted: true,
                },
            })
            gridStore.deleteBlock(featureId);
            NotificationManager.success(`Terrace successfully hidden!`);
            setNewTerraceId(null);
        } catch (e) {
            NotificationManager.error(e.message);
        }
    }, []);

    const gridType = useGridType();


    return <SidebarPage title={'Show or Hide Terraces'}>
        {gridType === GridTypes.terraced && <>
            <div>
                {Object.keys(terraceTypeToTitleMap).filter(type => {
                    if (type === ITerraceTypes.STAFF) {
                        return [...OrganizationTypesWithStaffBlock, "school", "district"].includes(organizationStore.currentOrganization.type);
                    }
                    if (type === ITerraceTypes.SHOP) {
                        return process.env.SHOP_TERRACE_BLOCK_ENABLED;
                    }
                    return true;
                }).map(type => {
                    const title = terraceTypeToTitleMap[type];
                    const existingTerrace = values(gridStore.blocks).find(b => b.type === type);

                    const featureButtonClassName = classNames({
                        [styles.featureButton]: true,
                        [styles.featureButtonRemove]: existingTerrace,
                    });

                    return <div key={type} className={styles.featureSection}>
                        <span>
                            {type === ITerraceTypes.SHOP && <NewTag className={styles.tagPosition}/>}
                            {title}
                        </span>
                        {existingTerrace ? <button aria-label={`Delete ${type} Terrace`} className={featureButtonClassName} onClick={() => deleteFeature(existingTerrace.id)}>
                            Hide
                        </button> : <button aria-label={`Add ${type} Terrace`} className={featureButtonClassName} onClick={() => addFeature(type as ITerraceTypes)}>
                            Show
                        </button>}
                    </div>
                })}
            </div>
        </>}
    </SidebarPage>
})

export default SidebarGridSettingsPage
